<template>
  <div class="container">
    <div class="flex-center min-vh-100 py-6 my-2 row">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
        <a class="text-decoration-none" href="/">
          <div class="d-flex flex-center flex-column mb-4 mt-4 mt-sm-0 text-brand">
            <img width="140" src="@/assets/images/logo/logo-le-bon-panier-black.svg" alt="Le bon panier.ca" class="mb-4">
            <span class="text-sans-serif">Espace Vendeur</span>
          </div>
        </a>

        <div class="card">
          <div class="card-body p-4 p-sm-5">
            <div class="heading mb-4">
              <h5>Inscrire ma boutique</h5>
            </div>

            <p>Veuillez fournir ces quelques informations afin de créer votre compte vendeur.</p>

            <div class="alert alert-danger" v-if="hasError">
              <div v-if="errorType === 'userNotFound' || errorType === 'wrongPassword'">Veuillez vérifier votre courriel ou votre mot de passe.</div>
              <div v-if="errorType === 'alreadyRegistered'">Cette adresse courriel est déjà enregistrée.</div>
              <div v-else>Une erreur interne s'est produite. Réessayez dans quelques instants.</div>
            </div>

            <form @submit.prevent="submit" class="register-form">
              <div class="form-row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="register-firstname">Prénom</label>
                    <input type="text" id="register-firstname" class="form-control" v-model="form.firstName" :class="{ 'border-danger': !!errors.firstName }">
                    <div v-if="!!errors.firstName" class="invalid-feedback">
                      {{ errors.firstName[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="register-lastname">Nom</label>
                    <input type="text" id="register-lastname" class="form-control" v-model="form.lastName" :class="{ 'border-danger': !!errors.lastName }">
                    <div v-if="!!errors.lastName" class="invalid-feedback">
                      {{ errors.lastName[0] }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="register-storename">Nom de votre boutique</label>
                <input type="text" id="register-storename" class="form-control" v-model="form.storeName" :class="{ 'border-danger': !!errors.storeName }">
                <div v-if="!!errors.storeName" class="invalid-feedback">
                  {{ errors.storeName[0] }}
                </div>
              </div>

              <div class="form-group">
                <label for="register-email">Adresse courriel</label>
                <input type="email" id="register-email" class="form-control" v-model="form.email" :class="{ 'border-danger': !!errors.email }">
                <div v-if="!!errors.email" class="invalid-feedback">
                  {{ errors.email[0] }}
                </div>
              </div>

              <div class="form-group">
                <label for="register-password">Mot de passe</label>
                <input type="password" id="register-password" class="form-control" placeholder="Minimum 8 caractères" v-model="form.password" :class="{ 'border-danger': !!errors.password }">
                <div v-if="!!errors.password" class="invalid-feedback">
                  {{ errors.password[0] }}
                </div>
              </div>

              <div class="form-check">
                <input id="newsletter-agreement" v-model="subscribeNewsletter" type="checkbox" class="form-check-input">
                <label for="newsletter-agreement" class="form-check-label newsletter-label">
                  J'accepte de m'inscrire et de recevoir l'infolettre
                </label>
              </div>

              <div class="form-group mt-3">
                <button class="btn btn-block btn-primary" type="submit" :disabled="isSubmitDisabled">Continuer</button>
              </div>
            </form>
          </div>
        </div>

        <div class="mt-4 d-flex justify-content-center">
          <router-link to="/login">Déjà inscrit? Accéder à mon compte vendeur</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import useValidator from '@/mixins/useValidator'
import http from '@/utils/http'

export default {
  mixins: [useValidator],
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        storeName: '',
        email: '',
        password: ''
      },
      subscribeNewsletter: false,
      isProcessing: false,
      hasError: false,
      errorType: null
    }
  },
  computed: {
    isSubmitDisabled () {
      const hasEmptyFields = Object.values(this.form).some(value => !(value.length > 0))
      return hasEmptyFields || this.isProcessing
    }
  },
  methods: {
    async submit () {
      const isValid = this.validate(this.form, {
        firstName: 'required',
        lastName: 'required',
        storeName: 'required',
        email: 'required|email',
        password: 'required|min:8'
      })

      if (!isValid) {
        return
      }

      this.isProcessing = true
      this.hasError = false

      try {
        await http.post('/v1/stores/register', { ...this.form, subscribeNewsletter: this.subscribeNewsletter })

        await this.$store.dispatch('login', {
          username: this.form.email,
          password: this.form.password
        })

        this.isProcessing = false
        this.$router.push('/')
      } catch (error) {
        this.isProcessing = false
        this.hasError = true

        const firebaseErrorCode = error.code
        const apiErrorCode = get(error, 'response.data.code')

        if (apiErrorCode === 'emailAlreadyRegistered') {
          this.errorType = 'alreadyRegistered'
        } else if (firebaseErrorCode === 'auth/user-not-found') {
          this.errorType = 'userNotFound'
        } else if (firebaseErrorCode === 'auth/wrong-password') {
          this.errorType = 'wrongPassword'
        } else {
          this.errorType = 'internal'
        }
      }
    }
  },
  created () {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
  }
}
</script>

<style scoped lang="scss">
.text-brand {
  font-size: 32px;
  font-weight: 800;
}

.register-form {
  label {
    font-weight: 600;
  }

  .newsletter-label {
    font-weight: normal;
  }
}

.invalid-feedback {
  display: block;
  font-size: 13px;
}
</style>
