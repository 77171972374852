<template>
  <div class="container">
    <div class="flex-center min-vh-100 py-6 my-2 row">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5">
        <a class="text-decoration-none" href="/">
          <div class="d-flex flex-center flex-column mb-4 text-brand">
            <img width="140" src="@/assets/images/logo/logo-le-bon-panier-black.svg" alt="Le bon panier.ca" class="mb-4">
            <span class="text-sans-serif">Espace Vendeur</span>
          </div>
        </a>

        <div class="alert alert-warning" v-if="$route.query.msg === 'requireAuthToConnectStore'">
          <div>Vous devez vous authentifier afin de finaliser la connexion à votre boutique externe.</div>
        </div>

        <div class="card">
          <div class="card-body p-5">
            <div class="heading mb-4">
              <h5>Connexion</h5>
            </div>

            <div class="alert alert-danger" v-if="hasError">
              <div v-if="errorType === 'userNotFound' || errorType === 'wrongPassword'">Veuillez vérifier votre courriel ou votre mot de passe.</div>
              <div v-else>Une erreur interne s'est produite. Réessayez dans quelques instants.</div>
            </div>

            <form @submit.prevent="submit">
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Adresse courriel" v-model="form.username">
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Mot de passe" v-model="form.password">
              </div>

              <div class="form-group">
                <button class="btn btn-block btn-primary" type="submit" :disabled="isSubmitDisabled">Continuer</button>
              </div>

              <p class="text-center forgot-pwd">
                <a :href="forgotLink" target="_blank">Mot de passe oublié?</a>
              </p>
            </form>
          </div>
        </div>

        <div class="mt-4 d-flex justify-content-center">
          <router-link to="/inscription">Créer un compte vendeur</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      isProcessing: false,
      hasError: false,
      errorType: null
    }
  },
  computed: {
    isSubmitDisabled () {
      return this.form.username.length === 0 || this.form.password.length === 0 || this.isProcessing
    },
    forgotLink () {
      return `${config.app.frontUrl}/recuperation-de-mot-de-passe`
    }
  },
  methods: {
    async submit () {
      this.isProcessing = true
      this.hasError = false

      try {
        await this.$store.dispatch('login', this.form)
        this.isProcessing = false

        const redirectUrl = localStorage.getItem('post_login_url')

        if (redirectUrl) {
          localStorage.removeItem('post_login_url')
          window.location.href = redirectUrl
        } else {
          this.$router.push('/')
        }
      } catch (error) {
        this.isProcessing = false
        this.hasError = true

        if (error.code === 'auth/user-not-found') {
          this.errorType = 'userNotFound'
        } else if (error.code === 'auth/wrong-password') {
          this.errorType = 'wrongPassword'
        } else {
          this.errorType = 'internal'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-brand {
  font-size: 32px;
  font-weight: 800;
}

.forgot-pwd {
  font-size: 13px;
}
</style>
